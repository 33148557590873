import {
  DropdownMenu,
  Icon,
  IconButton,
  Toggle,
  Tooltip,
} from '@kontentino/ui';
import { faArrowDownRight } from '@fortawesome/pro-regular-svg-icons/faArrowDownRight';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToLine';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons/faArrowUpRight';
import { faBarsSort } from '@fortawesome/pro-regular-svg-icons/faBarsSort';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faDatabase } from '@fortawesome/pro-regular-svg-icons/faDatabase';
import { faDollarCircle } from '@fortawesome/pro-regular-svg-icons/faDollarCircle';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faEllipsisVertical';
import { faFeather } from '@fortawesome/pro-regular-svg-icons/faFeather';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons/faShareNodes';
import { faTags } from '@fortawesome/pro-regular-svg-icons/faTags';
import { faText } from '@fortawesome/pro-regular-svg-icons/faText';
import { PostExportVariant } from 'constants/post';
import { OrderDirection } from 'app/types';
import React, { FC } from 'react';
import { UserPermission } from 'constants/userPermission';
import UserPermissionGate from 'components/UserPermissionGate';
import { useTranslation } from 'react-i18next';
import { usePostsLayoutState } from '../hooks/usePostsLayoutState';
import { preventDefault } from 'utils/mouseEvent';
import { usePostsContainer } from 'components/posts/context';
import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons/faArrowUpFromBracket';

const EXPORT_POSTS_OPTIONS = [
  {
    element: (label: string) => (
      <>
        <Icon icon={faArrowUpFromBracket} />
        {label}
      </>
    ),
    i18nKey: 'externalLink',
    key: PostExportVariant.ExternalLink,
    permission: UserPermission.POST_MASS_EXPORT_MULTIPREVIEW,
    isDisabled: (selectedPosts: number[]) =>
      selectedPosts.length === 0 ? 'noPostsSelectedToShare' : undefined,
  },
  {
    element: (label: string) => (
      <>
        <Icon icon={faFilePdf} />
        {label}
      </>
    ),
    i18nKey: 'PDF',
    key: PostExportVariant.Pdf,
    permission: UserPermission.POST_MASS_EXPORT,
  },
  {
    element: (label: string) => (
      <>
        <Icon icon={faDatabase} />
        {label}
      </>
    ),
    i18nKey: 'CSV',
    key: PostExportVariant.Csv,
    permission: UserPermission.POST_MASS_EXPORT,
  },
  {
    element: (label: string) => (
      <>
        <Icon icon={faText} />
        {label}
      </>
    ),
    i18nKey: 'textOnly',
    key: PostExportVariant.Txt,
    permission: UserPermission.POST_MASS_EXPORT,
  },
  {
    element: (label: string) => (
      <>
        <Icon icon={faImages} />
        {label}
      </>
    ),
    i18nKey: 'imagesOnly',
    key: PostExportVariant.Images,
    permission: UserPermission.POST_MASS_EXPORT,
  },
];

const SORT_POSTS_OPTIONS = [
  {
    i18nKey: 'newestToOldest',
    key: OrderDirection.Desc,
    element: (label: string) => (
      <>
        <Icon icon={faArrowDownRight} />
        {label}
      </>
    ),
  },
  {
    i18nKey: 'oldestToNewest',
    key: OrderDirection.Asc,
    element: (label: string) => (
      <>
        <Icon icon={faArrowUpRight} />
        {label}
      </>
    ),
  },
];

type Props = {
  onSortOptionSelect: (option: OrderDirection) => void;
  onExportOptionSelect: (option: PostExportVariant) => void;
  onTogglePreviews: () => void;
  selectedSortOption: OrderDirection;
  arePreviewsOn: boolean;
  canTogglePreviews: boolean;
  canToggleInspirationVisibility: boolean;
  canTogglePostsPageVisibility: boolean;
  canToggleBoostBudgetVisibility: boolean;
  canTogglePostsLabelsVisibility: boolean;
  disabled?: {
    exportPosts?: boolean;
  };
};

const PostsAdditionalActionsDropdown: FC<Props> = ({
  onSortOptionSelect,
  onExportOptionSelect,
  selectedSortOption,
  arePreviewsOn,
  onTogglePreviews,
  canTogglePreviews,
  canToggleInspirationVisibility,
  canTogglePostsPageVisibility,
  canToggleBoostBudgetVisibility,
  canTogglePostsLabelsVisibility,
  disabled,
}) => {
  const postsContainer = usePostsContainer();
  const {
    postStatusVariant,
    togglePostStatusVariant,
    isPostsPageVisible,
    togglePostsPageVisibility,
    isPostsLabelsVisible,
    togglePostsLabelsVisibility,
    isBoostBudgetVisible,
    toggleBoostBudgetVisibility,
    isInspirationsVisible,
    toggleInspirationsVisibility,
  } = usePostsLayoutState();
  const { t } = useTranslation();

  const noProfilesSelected =
    postsContainer.filterState.selectedProfiles.length === 0 &&
    postsContainer.filterState.selectedGroups.length === 0;

  const isPageVisibilityDisabled =
    !canTogglePostsPageVisibility ||
    (postsContainer.filterState.selectedProfiles.length === 1 &&
      postsContainer.filterState.selectedGroups.length === 0);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger disabled={noProfilesSelected}>
        <IconButton
          size={'medium'}
          disabled={noProfilesSelected}
          className="tw-h-9"
          variant="secondary"
          icon={<Icon icon={faEllipsisVertical} />}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="tw-z-10">
        <UserPermissionGate scopes={UserPermission.POST_MASS_EXPORT}>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger disabled={disabled?.exportPosts}>
              <DropdownMenu.Item
                data-name="posts-export"
                data-cy="posts-export"
              >
                <Icon icon={faArrowDownToLine} />
                {t('exportPosts')}
                <Icon size="sm" className="tw-ml-auto" icon={faChevronRight} />
              </DropdownMenu.Item>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent className="tw-z-10">
                {EXPORT_POSTS_OPTIONS.map((exportOption) => {
                  const disabledText = exportOption.isDisabled?.(
                    postsContainer.selectedPosts,
                  );

                  const item = (
                    <DropdownMenu.Item
                      disabled={!!disabledText}
                      data-name={`posts-export-${exportOption.key}`}
                      data-cy={`posts-export-${exportOption.key}`}
                      key={exportOption.key}
                      onClick={() => onExportOptionSelect(exportOption.key)}
                    >
                      {exportOption.element(t(exportOption.i18nKey))}
                    </DropdownMenu.Item>
                  );

                  return disabledText ? (
                    <Tooltip content={t(disabledText)}>
                      <div>{item}</div>
                    </Tooltip>
                  ) : (
                    item
                  );
                })}
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>
        </UserPermissionGate>
        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger>
            <DropdownMenu.Item data-name="posts-sort" data-cy="posts-sort">
              <Icon icon={faBarsSort} />
              {t('sortPosts')}
              <Icon size="sm" className="tw-ml-auto" icon={faChevronRight} />
            </DropdownMenu.Item>
          </DropdownMenu.SubTrigger>
          <DropdownMenu.Portal>
            <DropdownMenu.SubContent className="tw-z-10">
              {SORT_POSTS_OPTIONS.map((sortOption) => (
                <DropdownMenu.Item
                  data-name={`posts-sort-${sortOption.key}`}
                  data-cy={`posts-sort-${sortOption.key}`}
                  key={sortOption.key}
                  onClick={() => onSortOptionSelect(sortOption.key)}
                >
                  {sortOption.element(t(sortOption.i18nKey))}
                  {selectedSortOption === sortOption.key && (
                    <span>
                      <Icon icon={faCheck} className="tw-text-primary-100" />
                    </span>
                  )}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.SubContent>
          </DropdownMenu.Portal>
        </DropdownMenu.Sub>
        <DropdownMenu.Separator />
        <div className="tw-px-2 tw-text-sm tw-font-medium tw-text-grayscale-100">
          {t('style')}
        </div>
        <DropdownMenu.Item
          disabled={!canTogglePreviews}
          onSelect={preventDefault(onTogglePreviews)}
        >
          <Icon icon={faImages} />
          {t('showPreviews')}
          <Toggle
            data-name="posts-previews-toggle"
            onChange={() => {}}
            size="small"
            className="tw-ml-auto"
            checked={arePreviewsOn}
            disabled={!canTogglePreviews}
          />
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onSelect={preventDefault(togglePostsPageVisibility)}
          disabled={isPageVisibilityDisabled}
        >
          <Icon icon={faShareNodes} />
          {t('layoutFilter.showProfile')}
          <Toggle
            data-name="show-profile-toggle"
            onChange={() => {}}
            size="small"
            className="tw-ml-auto"
            checked={!isPageVisibilityDisabled && isPostsPageVisible}
            disabled={isPageVisibilityDisabled}
          />
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onSelect={preventDefault(togglePostsLabelsVisibility)}
          disabled={!canTogglePostsLabelsVisibility}
        >
          <Icon icon={faTags} />
          {t('layoutFilter.showLabels')}
          <Toggle
            data-name="show-labels-toggle"
            onChange={() => {}}
            size="small"
            className="tw-ml-auto"
            checked={canTogglePostsLabelsVisibility && isPostsLabelsVisible}
            disabled={!canTogglePostsLabelsVisibility}
          />
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onSelect={preventDefault(toggleBoostBudgetVisibility)}
          disabled={!canToggleBoostBudgetVisibility}
        >
          <Icon icon={faDollarCircle} />
          {t('layoutFilter.showBoostBudget')}
          <Toggle
            data-name="show-boost-budget-toggle"
            onChange={() => {}}
            size="small"
            className="tw-ml-auto"
            checked={canToggleBoostBudgetVisibility && isBoostBudgetVisible}
            disabled={!canToggleBoostBudgetVisibility}
          />
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onSelect={preventDefault(toggleInspirationsVisibility)}
          disabled={!canToggleInspirationVisibility}
        >
          <Icon icon={faFeather} />
          {t('layoutFilter.showInspirations')}
          <Toggle
            data-name="show-inspirations-toggle"
            onChange={() => {}}
            size="small"
            className="tw-ml-auto"
            checked={canToggleInspirationVisibility && isInspirationsVisible}
            disabled={!canToggleInspirationVisibility}
          />
        </DropdownMenu.Item>
        <DropdownMenu.Item onSelect={preventDefault(togglePostStatusVariant)}>
          <Icon icon={faCheckCircle} />
          {t('layoutFilter.classicStatusMode')}
          <Toggle
            data-name="classic-status-mode-toggle"
            onChange={() => {}}
            size="small"
            className="tw-ml-auto"
            checked={postStatusVariant === 'task'}
          />
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default PostsAdditionalActionsDropdown;
