import Logger from 'utils/logger';
import config from 'config';
import store from 'store';

declare global {
  interface Window {
    dataLayer?: {
      push: (...data: any) => void;
    };
  }
}

const getDataLayerBaseData = () => {
  const state = store?.getState();

  return {
    environment: config.MODE,
    platform: 'web',
    user: state.user.intercom || {},
    account: state.account || {},
  };
};

const DataLayer = {
  push: (data: { [x: string]: any }) => {
    try {
      const dataLayerData = {
        ...getDataLayerBaseData(),
        ...data,
      };

      if (!config.GTM_ID) {
        return Logger.log('Data layer would be pushed: %o', dataLayerData);
      }

      if (typeof window.dataLayer?.push === 'function') {
        Logger.log('Data layer pushed: %o', dataLayerData);
        return window.dataLayer.push(dataLayerData);
      }

      throw new Error('dataLayer is not defined on window');
    } catch (e) {
      Logger.error(e);
    }
  },
  events: {
    APP_PAGE_VIEW: 'app_page_view',
    SCHEDULE_POST: 'schedule_post',
    SCHEDULE_POST_ERROR: 'schedule_post_error',
    WELCOME_POSTS_VISITED: 'welcome_posts_visited',
    WELCOME_PLAY_WITH_DEMO: 'play_with_demo',
    WELCOME_CONNECT_PROFILES: 'welcome_connect_profiles',
    USER_SELECTED_LABEL_FROM_RECENT: 'user_selected_label_from_recent',

    POST_DETAIL_DUPLICATE_POST: 'post_detail_duplicate_post',
    POST_DETAIL_EDIT_POST: 'post_detail_edit_post',
    POST_DETAIL_DELETE_POST: 'post_detail_delete_post',
    POST_DETAIL_MOVE_POST: 'post_detail_move_post',
    POST_DETAIL_COPY_POST_LINK: 'post_detail_copy_post_link',
    POST_DETAIL_DOWNLOAD_POST_ASSETS: 'post_detail_download_post_assets',
    POST_DETAIL_SHARE_POST_EXTERNALLY: 'post_detail_share_post_externally',

    POST_CARD_CHANGE_POST_STATUS: 'post_card_change_post_status',
    POST_CARD_DUPLICATE_POST: 'post_card_duplicate_post',
    POST_CARD_EDIT_POST: 'post_card_edit_post',
    POST_CARD_DELETE_POST: 'post_card_delete_post',
    POST_CARD_MOVE_POST: 'post_card_move_post',
    POST_CARD_COPY_POST_LINK: 'post_card_copy_post_link',
    POST_CARD_SHARE_POST_EXTERNALLY: 'post_card_share_post_externally',
    POST_CARD_DOWNLOAD_POST_ASSETS: 'post_card_download_post_assets',
    REJECTED_REQUEST_RESPONSE: 'rejected_request_response',
    TIMEOUT_REQUEST_RESPONSE: 'timeout_request_response',
    APP_FAILED_TO_ERROR_BOUNDARY: 'app_failed_to_error_boundary',

    INSIGHT_LITE_INVITATION_MODAL_VISITED:
      'insight_lite_invitation_modal_visited',
    INSIGHT_LITE_INVITATION_MODAL_CLOSED:
      'insight_lite_invitation_modal_closed',
    INSIGHT_LITE_INVITATION_MODAL_INTERESTED:
      'insight_lite_invitation_modal_interested',
  },
};

export default DataLayer;
